/* Base */

@tailwind base;

html {
  font-size: 18px;
  min-height: 100%;
  background-image: url('../images/bg2.jpg');
}

body {
  min-height: 100%;
  background-color: rgba(242, 255, 211, 0.5);
}

/* Components */

@tailwind components;

.section {
  @apply py-4 px-4 mx-auto max-w-2xl;
}

/* .section:not(:last-child)::after {
  display: block;
  content: '';

  @apply bg-primary mx-8 mt-8;
  height: 1px;
} */

@screen md {
  .section {
    @apply px-6;
  }
}

.form {}

.form__group {
  @apply flex flex-wrap;
}

.form__item {
  @apply flex flex-col w-full mb-3;
}

.form__item.form__item--full {
  width: 100%;
  flex: 0 1 100%;
}

.form__label {
  @apply font-bold mb-1;
}

.form__input {
  @apply w-full border border-gray py-2 px-4 transition duration-200 appearance-none rounded-none;
}

.form__input:focus {
  @apply border-dark;
}

@screen md {
  .form__item:not(.form__item--full) {
    @apply w-1/2;
  }

  .form__item:not(.form__item--full):nth-child(odd) {
    @apply pr-2;
  }

  .form__item:not(.form__item--full):nth-child(even) {
    @apply pl-2;
  }
}

.image-link {
  @apply relative w-full h-auto block shadow rounded border-4 border-white;
  transition: .25s ease;
  cursor: zoom-in;
  --transform-rotation: 2.5deg;
  --transform-scale: 1;
  /* tranzlateZ and all below transform rule should help with antialiasing. */
  transform:
    rotate(var(--transform-rotation))
    scale(var(--transform-scale))
    translateZ(0)
  ;
  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  -webkit-perspective: 1000;
}

.image-link:hover {
  --transform-rotation: 0deg !important;
  --transform-scale: 1.05;
}

.image-stack {
  @apply relative flex items-center justify-center w-full;
  cursor: zoom-in;
}

.image-stack > * {
  position: relative;
  max-width: 10rem;
}

.image-stack > .image-link:nth-child(odd) {
  --transform-rotation: -2.5deg;
}

.image-stack > .image-link:not(:last-child) {
  @apply mr-8;
}

@screen md {
  .image-stack {
    @apply flex-col justify-start;
  }

  .image-stack > .image-link {
    @apply mr-0;
    left: 0.5rem;
    max-width: none;
  }

  .image-stack > .image-link:nth-child(odd) {
    left: -0.5rem;
  }

  .image-stack > .image-link:not(:last-child) {
    @apply mb-8 mr-0;
  }
}

/* Utilities */

@tailwind utilities;